<template>
  <div class="selling-container">
    <steps-counter />
    <sign-up-step v-if="getActiveStep === 'register'" />
    <add-products-step v-if="getActiveStep === 'product'" />
    <delivery-step v-if="getActiveStep === 'shipping'" />
    <product-verification-step v-if="getActiveStep === 'confirm'" />
  </div>
</template>

<script>
import StepsCounter from 'theme/components/core/blocks/Selling/StepsCounter.vue'
import SignUpStep from 'theme/components/core/blocks/Selling/SignUpStep.vue'
import { mapGetters, mapActions } from 'vuex'
import { isServer } from '@vue-storefront/core/helpers'
import { isCanBeReached } from 'theme/helpers/selling-products'

export default {
  name: 'Selling',
  components: {
    StepsCounter,
    SignUpStep,
    AddProductsStep: () => import('theme/components/core/blocks/Selling/AddProductsStep.vue'),
    DeliveryStep: () => import('theme/components/core/blocks/Selling/DeliveryStep.vue'),
    ProductVerificationStep: () => import('theme/components/core/blocks/Selling/ProductVerificationStep.vue')
  },
  data () {
    return {
    }
  },
  watch: {
    '$route': 'activateHashStep',
    isLoggedIn () {
      this.$store.dispatch('selling/setFirstStep')
    }
  },
  mounted () {
    this.$store.dispatch('selling/setFirstStep')
  },
  computed: {
    ...mapGetters({
      isLoggedIn: 'user/isLoggedIn',
      getProductsCount: 'selling/getProductsCount',
      getActiveStep: 'selling/getActiveStep',
      getSteps: 'selling/getSteps'
    })
  },
  created () {
    if (!isServer) window.addEventListener('beforeunload', this.beforeWindowUnload)
  },
  beforeDestroy () {
    window.removeEventListener('beforeunload', this.beforeWindowUnload)
  },
  beforeRouteLeave (to, from, next) {
    if (!to.name.includes('thank-you-for-selling-page') && this.confirmStayInDirtyForm()) {
      next(false)
    } else {
      window.scrollTo(0, 0)
      next()
    }
  },
  methods: {
    confirmLeave () {
      return window.confirm(this.$t('Do you really want to leave? you have unsaved products!'))
    },
    confirmStayInDirtyForm () {
      return this.getProductsCount && !this.confirmLeave()
    },
    beforeWindowUnload (e) {
      if (this.confirmStayInDirtyForm()) {
        e.preventDefault()
        e.returnValue = ''
      }
    },
    activateHashStep () {
      if (!isServer) {
        var urlStep = window.location.hash.replace('#', '')
        if (urlStep === '') {
          this.$store.dispatch('selling/setFirstStep')
        } else {
          if (isCanBeReached(urlStep)) this.$store.dispatch('selling/goToStep', urlStep)
        }
      }
    }
  }
}
</script>

<style lang="scss" scoped>
 .selling-container {
    min-height: 80vh;
 }
</style>
