<template>
  <div>
    <div class="register-outer">
      <div class="register-inner">
        <div class="register-header">
          <h2> {{ $t('Sell with us') }}</h2>
          <p> {{ $t("it's so simple!") }}</p>
        </div>
        <RegisterForm v-if="isRegister" :is-single="true" @emitSwitch="switchElem" />
        <LoginForm v-else-if="isLogin" :is-single="true" @emitSwitch="switchElem" />
        <ForgotPass v-else-if="isForgotPass" :is-single="true" @emitSwitch="switchElem" />
      </div>
    </div>
    <worth-choose-us-block />
    <how-it-works-block />
    <what-can-i-sell-block />
  </div>
</template>

<script>
import { mapState } from 'vuex'

export default {
  name: 'SignUpStep',
  data () {
    return {
      isRegister: true,
      isLogin: false,
      isForgotPass: false
    }
  },
  components: {
    RegisterForm: () => import('theme/components/core/blocks/Auth/Register.vue'),
    LoginForm: () => import(/* webpackChunkName: "login" */ 'theme/components/core/blocks/Auth/Login.vue'),
    ForgotPass: () => import(/* webpackChunkName: "login" */ 'theme/components/core/blocks/Auth/ForgotPass'),
    WorthChooseUsBlock: () => import('theme/components/core/blocks/Home/WorthChooseUsBlock.vue'),
    HowItWorksBlock: () => import('theme/components/core/blocks/Home/HowItWorksBlock.vue'),
    WhatCanISellBlock: () => import('theme/components/core/blocks/Home/WhatCanISellBlock.vue')
  },
  computed: {
    ...mapState({
      activeElem: state => state.ui.authElem
    })
  },
  methods: {
    switchElem (e) {
      if (e === 'login') {
        this.isRegister = false
        this.isForgotPass = false
        this.isLogin = true
        window && window.scrollTo(0, 0)
      } else if (e === 'register') {
        this.isLogin = false
        this.isForgotPass = false
        this.isRegister = true
        window && window.scrollTo(0, 0)
      } else if (e === 'forgot-pass') {
        this.isLogin = false
        this.isRegister = false
        this.isForgotPass = true
        window && window.scrollTo(0, 0)
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.register {
  &-outer {
    display: flex;
    background-color: #2D2D2D;
    background-image: url(/assets/sell-bcg.jpg);
    background-position-x: center;
    background-position-y: top;
    background-repeat: no-repeat;
    background-size: cover;
  }
  &-inner {
    /deep/ .modal-content {
      padding: 10px 16px;
    }
    background-color: white;
    max-width: 767px;
    width: 767px;
    margin: 20px auto;
    @media (max-width: 767px) {
      margin: 0;
    }
  }
  &-header {
    > h2 {
      font-weight: 400;
      margin-bottom: 0;
      text-transform: uppercase;
      @media (max-width: 767px) {
        font-size: 22px;
      }
    }
    > p {
      margin: 0;
      text-transform: uppercase;
    }
    text-align: center;
  }
}
</style>
