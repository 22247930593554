<template>
  <div class="row center-xs mb40 mx20">
    <div v-for="(step, index) in Object.keys(getSteps)" :key="step" class="mt20 step-container">
      <div class="dot" @click="goTo(step)">
        <div v-if="getActiveStepIndex >= index" class="dot-full" />
        <p class="paragraph">
          {{ $t(steps[step]) }}
        </p>
      </div>
      <hr v-if="Object.keys(getSteps).length - 1 !== index" :class="{ bold: getActiveStepIndex > index }" class="line">
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { isCanBeReached } from 'theme/helpers/selling-products'

export default {
  name: 'StepsCounter',
  data () {
    return {
      steps: {
        register: 'Create account',
        product: 'Add products',
        shipping: 'Delivery',
        confirm: 'Product review'
      }
    }
  },
  computed: {
    ...mapGetters({
      getActiveStep: 'selling/getActiveStep',
      getActiveStepIndex: 'selling/getActiveStepIndex',
      getSteps: 'selling/getSteps'
    })
  },
  methods: {
    goTo (step) {
      return isCanBeReached(step) && this.$store.dispatch('selling/goToStep', step)
    }
  }
}
</script>

<style lang="scss" scoped>
  .step-container {
    display: flex;
    flex-wrap: nowrap;
    @media (min-width: 768px) and (max-width: 930px) {
      margin-top: 40px;
    }
    @media (max-width: 991px) {
      &:not(:last-child) {
        flex: 1;
      }
    }
  }
  .dot {
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    min-width: 20px;
    min-height: 20px;
    margin: 0 20px;
    border: 1px black solid;
    border-radius: 50%;
  }
  .dot-full {
    width: 10px;
    height: 10px;
    border: 1px black solid;
    background-color: black;
    border-radius: 50%;
  }
  .line {
    width: 180px;
    margin: auto 0;
    &.bold {
      border: 1px solid black;
    }
    @media (max-width: 991px) {
      width: 100%;
    }
  }
  .paragraph {
    position: absolute;
    top: 17px;
    font-size: 12px;
    letter-spacing: 1.06px;
    white-space: nowrap;
    @media (max-width: 991px) {
      white-space: unset;
    }
  }
</style>
